/*Typography */
h1,
h2,
h3 {
  color: var(--color-primary);
  margin-bottom: 1rem;
}
h1 {
  font-size: 5rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3rem;
}

p {
  margin-top: 0;
}

hr {
  filter: brightness(1.5);
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  p {
    font-size: 1.7rem;
  }
}
/*Links */
a:link {
  text-decoration: none !important;
  cursor: pointer;
}
.link-arrow {
  color: var(--color-accent);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}
.link-arrow::after {
  content: "\279C";
  margin-left: 0.75rem;
  transition: margin 0.15s;
}
.link-arrow:hover::after {
  margin-left: 1.5rem;
}

.link-left-arrow {
  color: var(--color-accent);
  font-size: 2rem;
  font-weight: bold;
}

.link-left-arrow::before {
  content: "\2190";
  margin-right: 0.75rem;
  transition: margin 0.15s;
}

.link-left-arrow:hover::before {
  margin-right: 1.5rem;
}

.router-link {
  font-size: 2rem;
  text-decoration: underline;
  color: var(--color-primary);
  margin: 0 1rem;
}

.router-link:hover {
  color: var(--color-body);
}

@media screen and (min-width: 1024px) {
  .link-arrow {
    font-size: 1.5rem;
  }

  .link-left-arrow {
    font-size: 1.5rem;
  }

  .router-link {
    font-size: 1.5rem;
  }
}

.italic {
  font-style: italic;
}
