.small-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small-card-container .link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.small-card-container img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 10%);
}

.small-card-container h3 {
  color: var(--color-secondary);
  font-weight: 400;
  text-align: center;
}

.small-card-container span {
  text-align: center;
}


/* @media (max-width: 820px) {
  .small-card-container img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 10%);
  }
} */