/* Media */

.media {
  display: flex;
  margin-bottom: 4rem;
}

.media__title {
  margin-top: 0;
}

.media__body {
  margin: 0 2rem;
}

.media__image {
  margin-top: 1.5rem;
  color:var(--color-secondary);
}

.media__icon {
background: rgba(40, 50, 96,0.15);
border-radius: 25px;
padding: 1rem;
}