.footer-container {
  margin-top: 7rem;
}

.footer__brand {
  margin-top: 5rem;
  text-align: center;
  position: relative;
}

.footer__brand img {
  max-width: 230px;
  background: #fff;
  padding: 1rem 3rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.4), 0 6px 20px 0 rgba(0,0,0,.1);
  border-radius: 2px;
  width: 230px;
  height: (151px/423px)*230;
}

.footer-copyright {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  display: inline-block;
}

.copyright {
  font-size: 2rem;
  opacity: 0.7;
}

.terms-of-use {
  display: block;
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .footer__brand p {
    font-size: 1.5rem;
  }
}