/* lists */

.list {
  list-style: none;
  padding-left: 0;
  color: var(--color-primary);
  font-size: bold;
}
.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}
.list--tick {
  padding-left: 3rem;
}
.list--tick .list__item::marker {
  content: "✓ ";
  color: var(--color-secondary);
}
.list--tick .list__item {
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}
.list--icon .list__item {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .list--tick .list__item {
    padding-left: 0;
  }
}
