.contact-container {
  width: 65%;
  margin: 4rem auto;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.contact-container .contact-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-container .contact-content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}

.contact-content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}

.contact-content .left-side .details {
  margin: 14px;
  text-align: center;
}

.contact-content .left-side .details i {
  font-size: 30px;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.contact-content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}

.contact-content .left-side .details .text-one,
.contact-content .left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}

.contact-container .contact-content .right-side {
  width: 75%;
  margin-left: 75px;
}

.contact-content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: var(--color-primary);
}

.contact-content .right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}

.contact-content .right-side .input-box input,
.contact-content .right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.contact-content .right-side .message-box {
  min-height: 110px;
}

.contact-content .right-side .input-box textarea {
  padding-top: 6px;
}

.contact-content .right-side .button {
  display: inline-block;
  margin-top: 12px;
}

.contact-content .right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: var(--color-primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-content .button input[type="button"]:hover {
  background: var(--color-primary);
}

@media (max-width: 950px) {
  .contact-container {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }

  .contact-container .contact-content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}

@media (max-width: 820px) {
  .contact-container {
    margin: 10rem auto;
    width: 100%;
    height: 100%;
  }

  .contact-container .contact-content {
    flex-direction: column-reverse;
  }

  .contact-container .contact-content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .contact-container .contact-content .left-side::before {
    display: none;
  }

  .contact-container .contact-content .right-side {
    width: 100%;
    margin-left: 0;
  }

  .contact-container .contact-content .right-side .information-text {
    display: none;
  }
}
