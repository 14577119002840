.box_1 {
  height: 100px;
  background: gold;
  margin-bottom: 20px;
}
.box_2 {
  height: 100px;
  background: blue;
}

.grid {
  display: grid;
}

.grid--gap-1 {
  grid-gap: 1rem;
}

.grid--gap-2 {
  grid-gap: 2rem;
}

.grid--gap-3 {
  grid-gap: 3rem;
}

.grid--gap-4 {
  grid-gap: 4rem;
}

.grid--ctr-item {
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .grid--1x4 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .grid--1x4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
