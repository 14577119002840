.nav {
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
}

.nav--list {
  width: 100%;
  margin: 0;
}

.nav--item {
  padding: 0.5rem 2rem;
  border-bottom: 1px solid var(--color-secondary);
  color: var(--color-primary);
}

.nav--link {
  color: var(--color-primary);
}

.nav--toggler {
  opacity: 0.5;
  cursor: pointer;
}

.nav.collapsible--expanded .nav--toggler {
  opacity: 0.9;
  box-shadow: 0 0 3px 3px #29335dd1;
  border-radius: 5px;
}

.collapsible--content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible--expanded .collapsible--content {
  max-height: 100%;
  opacity: 1;
}
.nav--item:last-of-type {
  border-bottom: none;
}

.nav--link:hover {
  color: #29335dd1;
}

.logo--navbar {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif; 
}

@media screen and (min-width: 768px) {
  .nav--toggler {
    display: none;
  }
  .nav--list {
    width: auto;
    display: flex;
    font-size: 1.4rem;
    max-height: 100%;
    opacity: 1;
  }
  .nav--item {
    border: 0;
    padding: 10px;
  }
}
