.grid--container {
  padding: 1rem;
  align-items: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  width: 100%;
  height: 0;
}

.hero--container img {
  width: 100%;
  aspect-ratio: 74 / 43;
  object-fit: cover;
  margin-top: 5rem;
}

.p--light {
  color: #fff;
}

.socialMedia-container {
  display: grid;
  justify-content: center;
}

.socialMedia--icons {
  display: flex;
  justify-content: space-around;
}

.socialMedia--icons a {
  text-decoration: none;
  color: inherit;
}
.btn--devis {
  position: relative;
}

.btn--devis::after {
  content: 'A partir de 899€ \A Offre pour les particuliers';
  white-space: pre;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  font-size: 20px;
  animation: slideInOut 2s infinite;
  color: #f6f623;
}


@keyframes slideInOut {
  0%, 100% {
    transform: translateX(-50%) scale(0.8);
  }
  50% {
    transform: translateX(-50%) scale(1);
  }
}

@media screen and (min-width: 768px) {
  .hero__content img {
    margin-top: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero__content {
    text-align: left;
    align-self: center;
  }

  .hero__content img {
    width: 100%;
    aspect-ratio: 74 / 43;
    object-fit: cover;
    margin-top: 5rem;
  }
  
  .box {
    position: relative;
    border-image: linear-gradient(to bottom right, transparent 70%, white 70%) 1
      100%;
    color: white;
    padding: 40px 20px;
    width: fit-content;
    height: auto;
    margin: 20px 0;
  }

  .box::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: 70%;
    bottom: 50%;
    border-top: solid 2px white;
    border-left: solid 2px white;
    border-right: solid 2px transparent;
    border-bottom: solid 2px transparent;
  }

  .box::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 70%;
    right: -2px;
    bottom: -2px;
    border-top: solid 2px transparent;
    border-left: solid 2px transparent;
    border-right: solid 2px white;
    border-bottom: solid 2px white;
  }

  .btn--devis {
    position: absolute;
  }

  .video-container {
    padding-bottom: 10px;
    height: auto;
  }

  .socialMedia-container {
    margin-top: -50px;
  }
}
