@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

:root {
  --color-primary: #283260;
  --color-secondary: #89be2e;
  --color-accent: rgb(237, 182, 13);
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  color: var(--color-body);
  line-height: 1.5;
  font-size: 2.4rem;
}

.containter {
  max-width: 1140px;
  margin: 0 auto;
}