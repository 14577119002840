.post-page {
    padding: 1rem;
}

.post-title {
    margin-top: 5px;
    text-transform: uppercase;
    padding: 0;
}

.post-underline {
    display: block;
    height: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-position: 0 0;
    background-repeat: repeat-x;
}

.mrt-4 {
    margin-top: 4rem;
}