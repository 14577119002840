.quote{
  font-size: 3rem;
  font-style: italic;
  color : var(--color-body-darker);
  line-height: 1.3;
}

.quote__text::before{
  content: open-quote;
}

.quote__text::after{
  content: close-quote;
}
.quote__author{
  font-size: 3rem;
  font-weight: 400;
}
.quote__organization{
  color:var(--color-body-darker);
  opacity: 0.6;
  font-size:2rem;
  font-style:normal;
}
.quote__icon{
  position: relative;
  bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .quote{
    font-size: 2rem;
  }
  .quote__author{
    font-size: 2.4rem;
  }
  .quote__organization{
    font-size: 1.6rem;
  }
}