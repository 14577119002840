.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-item {
  /*flex: 1;*/
  min-width: calc(25% - 20px); /* Adjust the width as needed */
  margin: 3rem 1rem; /* Adjust the margin as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-item img {
  width: 220px;
  aspect-ratio: 16/9;
  /* height: 110px; */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 10%);
}

@media screen and (min-width: 1024px) {
  .image-item img {
    width: 190px;
    /* height: 90px; */
    aspect-ratio: 16/9;
  }
}
