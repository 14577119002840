.feature {
  gap: 4rem 2rem;
  margin-top: 12rem;
}

.feature:first-of-type {
  margin-top: 6rem;
}

.feature__image {
  width: 100%;
  height: auto;
  margin: auto;
}

.feature__image img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .feature {
    gap: 4rem 4rem;
    margin-top: 12rem;
  }

  .feature__image {
    aspect-ratio: 192/139;
    max-width: 700px;
    min-width: 300px;
    margin: auto auto 0 auto;
  }
}
