/*Badges */

.badge {
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 2rem;
  white-space: nowrap;
}

.badge--primary {
  background: var(--color-primary);
  color: #fff;
}
.badge--secondary {
  background: var(--color-secondary);
  color: #fff;
}
.badge--accent {
  background: var(--color-accent);
  color: #fff;
}

.badge--small {
  font-size: 1.6rem;
}

@media screen and (min-width: 1024px) {
  .badge {
    font-size: 1.5rem;
  }
  .badge--small {
    font-size: 1.2rem;
  }
}