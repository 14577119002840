.btn {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0;
  text-transform: uppercase;
  padding: 2rem 4vw;
  border: 0;
  border-radius: 40px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  outline: 0;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: rgba(40, 50, 96, 0.8);
}
.btn--secondary {
  background: var(--color-secondary);
  color: #fff;
}
.btn--secondary:hover {
  background: rgba(137, 190, 46, 0.8);
}
.btn--accent {
  background: var(--color-accent);
  color: var(--color-primary);
}
.btn--accent:hover {
  background: rgba(237, 182, 13, 0.8);
}
.btn--block {
  width: 100%;
  display: inline-block;
}
.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media screen and (min-width: 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}
