.icon-container {
  background: #f3f9fa;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--secondary {
  fill: var(--color-secondary);
}

.icon--accent {
  fill: var(--color-accent);
}

.icon-svg-accent svg path {
  fill: var(--color-accent);
}

.icon-list {
  margin-right: 2rem;
}

@media screen and (min-width: 1024px) {
}
