.block {
  --padding-vertical: 5rem;
  padding: var(--padding-vertical) 2rem;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.block--secondary {
  background: var(--color-secondary);
  color: #5b6385;
}

.block--skewed-left,
.block--skewed-right,
.block--skewed-top-right {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
}

.block--skewed-left {
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
}

.block--skewed-top-right {
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 100%);
}

.block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block__sub-header {
  text-align: center;
  margin-bottom: 6rem;
}

.block__header strong,
.block__sub-header strong {
  color: var(--color-secondary);
}

.block__heading {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
}
