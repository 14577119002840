.feature-right-content {
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 10%), 0 1px 8px 0 rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.feature-right-content li {
  list-style: none;
  padding: 1rem 2rem;
}

@media screen and (min-width: 1024px) {
  .feature-right-content li {
    padding: 1rem 1rem;
  }

  .about__container {
    gap: 4rem 6rem;
  }
}
