.material-container {
  margin-top: 6rem;
  position: relative;
}

.material-image {
  overflow: hidden;
}

.material-image img {
  width: 100%;
  /* height: 350px; */
  box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 10%), 0 1px 8px 0 rgb(0 0 0 / 20%);
  border-radius: 5px;
  transition: 1s ease;
}

.trans_1_1 img {
  transform: scale(1.1);
}

@media screen and (min-width: 768px) {
  .laboratory-material .feature__content {
    order: 2;
  }
}

@media screen and (min-width: 1024px) {
  .material-common {
    position: relative;
  }

  .material-common::before,
  .material-common::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #cccccc42;
    z-index: -1;
  }

  .material-common::before {
    top: 61%;
    left: 46%;
    transform: rotate(45deg);
  }

  .material-common::after {
    top: 18%; /* Set the vertical position of the second square */
    left: 35.5%; /* Set the horizontal position of the second square */
    transform: rotate(30deg);
  }
}
