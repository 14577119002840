.card-image {
  position: relative;
  background: white;
  width: 350px;
  height: auto;
  padding: 0px 0px 5px 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 70%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  transition: transform 250ms;
}

.card-image:hover {
  transform: translateY(-15px);
}

.card-image img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #e4e4bb;
  width: 100%;
  height: 233px;
}

.card-image .card-detail {
  padding: 10px 20px 10px 20px;
}

.card-detail h3 {
  text-align: center;
  font-size: 2.6rem;
  margin-block-start: 1rem;
}
.card-detail li {
  font-size: 2rem;
}

@media screen and (min-width: 1200px) {
  .card-image {
    width: 300px;
  }
  .card-image img {
    height: 200px;
  }
  .card-detail h3 {
    text-align: center;
    font-size: 2.2rem;
  }
  .card-detail li {
    font-size: 1.7rem;
  }
}
